<template>
  <TTView>
    <VRow>
      <VCol>
        TTTextarea
      </VCol>
    </VRow>
    <TTExample
      file="components/TTTextarea/examples/TTTextarea.full"
    />
    <TTExample
      file="components/TTTextarea/examples/TTTextarea.noCounter"
    />
    <TTExample
      file="components/TTTextarea/examples/TTTextarea.noCounterAndTitle"
    />
    <TTExample
      file="components/TTTextarea/examples/TTTextarea.noDescription"
    />
    <TTExample
      file="components/TTTextarea/examples/TTTextarea.fieldsOnly"
    />
    <TTExample
      file="components/TTTextarea/examples/TTTextarea.unactive"
    />
  </TTView>
</template>

<script>

export default {
  name: 'TextareaView',
};
</script>
